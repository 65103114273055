var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
          [
            _c("c-table", {
              ref: "grid1",
              attrs: {
                topBorderClass: "topcolor-lightblue",
                title: "평가항목",
                tableId: "grid1",
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                editable: _vm.editable,
                filtering: false,
                hideBottom: true,
                columns: _vm.grid1.columns,
                data: _vm.grid1.data,
                gridHeight: _vm.gridheight,
                isExcelDown: false,
              },
              on: { rowClick: _vm.rowClick },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
          [
            _c("c-table", {
              ref: "grid2",
              attrs: {
                topBorderClass: "topcolor-lightblue",
                title: "구분",
                tableId: "grid2",
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                editable: _vm.editable,
                filtering: false,
                hideBottom: true,
                columns: _vm.grid2.columns,
                data: _vm.grid2.data,
                gridHeight: _vm.gridheight,
                isExcelDown: false,
              },
              on: { rowClick: _vm.rowClick2 },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c("c-table", {
              ref: "grid3",
              attrs: {
                topBorderClass: "topcolor-lightblue",
                title: "평가항목별 세부항목",
                tableId: "grid3",
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                editable: _vm.editable,
                filtering: false,
                hideBottom: true,
                columns: _vm.grid3.columns,
                data: _vm.grid3.data,
                gridHeight: _vm.gridheight,
                isExcelDown: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "customCol"
                        ? [
                            _c(_vm.imprComponent, {
                              tag: "component",
                              attrs: {
                                col: col,
                                props: props,
                                inputEditable: _vm.editable,
                                isImmShow: true,
                                requestContentsCols: _vm.requestContentsCols,
                                tableKey: "selfInspectionResultItemId",
                                ibmTaskTypeCd: "ITT0000035",
                                ibmTaskUnderTypeCd: "ITTU000060",
                              },
                              on: { imprChange: _vm.imprChange },
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }